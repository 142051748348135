<script setup lang="ts">
import site from "~~/site"

definePageMeta({
  // layout: 'default',
  // name: site.name,
  // alias: 'index',
  title: site.slogon,
  description: 'We will provide the best hibachi catering experience of your life! No need to leave your own backyard! We bring our own hibachi grill and chef to you.',
  keywords: 'hibachi,hibachi near me,hibachi catering,hibachi party,hibachi at home,mobile hibachi, florida',
  navOrder: 1,
  type: 'primary',
  icon: 'i-mdi-home',
  // ogImage: 'images/ogImage.png', // url or local images inside public folder, for eg, ~/public/images/ogImage.png
})

//显示 google 搜索结果的名字
useJsonld({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
   name: 'Hibachi Now',
   "url" : "https://hibachinow.com/"
});

</script>
<template>
  <div class="-mt-24">
    <HeroSection class="pt-24"/>
    <!-- <CallToAction /> -->
    <MiniDivider :show-line="false"/>
    <HowItWorks />
    <MiniDivider :show-line="false"/>
    <RegionListLite />
    <MiniDivider :show-line="false"/>
    <UserReviews />
    <MiniDivider :show-line="false"/>
    <UserGallery />

    <!-- <UserStories/> -->
    <!-- <StatsSection /> -->
  </div>
</template>
<style scoped></style>
