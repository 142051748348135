<script setup lang="ts">
const photos = ref([])

onMounted(() => {
  for (let i = 1; i <= 8; i++) {
    photos.value.push({ src: `/images/gallery/x${i}.jpg`, type: 'image' })
  }
})

</script>

<template>
  <div class="container mx-auto flex flex-col items-center justify-center">
    <SectionHeader title="Top Mobile Hibachi Catering" desc="Get a Glimpse of Our Catering Events">
      <template #desc>
        Get a Glimpse of Our Catering Events.
      </template>
    </SectionHeader>

    <ImageGrid :images="photos" :preview="false" width="300px" height="300px" />
    
    <NuxtLink to="/gallery">
      <a type="button" class="text-neutral-500 hover:text-neutral-600 mt-2 underline">
        Browse More
      </a>
    </NuxtLink>
  </div>

</template>
